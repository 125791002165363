/* Banner.css */
.banner-wrap {
    overflow: hidden;
    position: relative;
    background-color: #1e1e30;
  }
  
  /* Background image container - keeping the eye perfect */
  .banner-background-image {
    position: absolute;
    top: 0;
    right: -20%;
    bottom: 0;
    width: 70%;
    overflow: visible;
    z-index: 1;
    pointer-events: none;
  }
  
  /* The actual image - keeping it exactly as it was */
  .full-width-eye-image {
    height: 100%;
    width: auto;
    max-width: none;
    object-fit: contain;
    object-position: right center;
  }
  
  /* Content area */
  .banner-content {
    position: relative;
    z-index: 2;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  
  /* Title styling */
  .banner-content .title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: white;
    line-height: 1.2;
  }
  
  .embrace-text {
    background-color: #8a2be2;
    padding: 5px 10px;
  }
  
  /* Description styling */
  .banner-content .description {
    font-size: 1.1rem;
    margin-bottom: 30px;
    color: white;
    opacity: 0.9;
  }
  
  /* Button styling */
  .btn-dark {
    background-color: #1e1e30;
    color: white;
    border: 2px solid #06EFC5;
    padding: 10px 25px;
    border-radius: 5px;
    font-weight: bold;
    transition: all 0.3s ease;
  }
  
  .btn-dark:hover {
    background-color: #06EFC5;
    color: #1e1e30;
  }
  
  /* Testimonial card styling */
  .testimonial-card {
    margin-top: 40px;
    background-color: rgba(30, 30, 48, 0.8);
    padding: 20px;
    border-radius: 10px;
    position: relative;
    max-width: 90%;
  }
  
  .testimonial-card .title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
  }
  
  .testimonial-card .title img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
  }
  
  .testimonial-card .description {
    font-size: 1rem;
    margin-bottom: 15px;
  }
  
  .testimonial-card .client-name {
    font-weight: bold;
    font-style: italic;
  }
  
  .testimonial-quote {
    position: absolute;
    top: -15px;
    left: 20px;
  }
  
  .testimonial-quote img {
    width: 30px;
    height: auto;
  }
  
  /* Make sure the row has enough height */
  .banner-wrap .row {
    min-height: 600px;
  }
  
  /* Mobile styles */
  @media (max-width: 991px) {
    .banner-background-image {
      display: none;
    }
    
    .mob-banner-img {
      display: block;
      margin: 20px 0;
    }
    
    .testimonial-card {
      max-width: 100%;
    }
    
    .banner-content .title {
      font-size: 2rem;
    }
  }
  
  @media (min-width: 992px) {
    .mob-banner-img {
      display: none;
    }
  }